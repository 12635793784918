import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import dayjs from 'dayjs';

import {
  ChevronRightIcon,
  ClipboardDocumentCheckIcon,
} from '@heroicons/react/24/outline';
import { WorkEvent } from '../../../../../typings/backend-types';
import {
  EventDescriptionItem,
  EventDescriptionLink,
} from '../EventDescriptionItem';
import { Routes } from '../../../../../utilities/routes';
import { formatName } from '../../../../../utilities/formatName';
import { Button } from '../../../../Common/Buttons/Button';
import { formatCurrency } from '../../../../../utilities/formatCurrency';
import { getTextColor } from '../../../../../utilities/textColorByNumber';
import containsAllNull from '../../../../../utilities/containsAllNull';
import { gmapsNavigationLink } from '../../../../../utilities/googleMapsLink';
import { formatAddress } from '../../../../../utilities/formatAddress';
import useContract, {
  RestrictedFeatures,
} from '../../../../../hooks/useContract';
import useUserReadAccess from '../../../../../hooks/useUserReadAccess';
import { Permission } from '../../../../../typings/roleConfig';
import { formatNameWithLabel } from '../../../../../utilities/students/formatNameWithLabel';

export const EventDetails: FunctionComponent<{ fetchedEvent: WorkEvent }> = ({
  fetchedEvent,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendarPopups.viewWorkEvent',
  });
  const { isFeatureEnabled } = useContract();
  const hasReadAccess = useUserReadAccess();

  const syllabusProgressCard =
    fetchedEvent.student?.syllabusProgressCards?.find(
      (c) =>
        c.syllabus?.licenseCategory ===
        fetchedEvent.billingType?.licenseCategory,
    );

  let balanceInLessons = fetchedEvent?.student?.balanceInLessons;

  if (
    process.env.NEXT_PUBLIC_ENABLE_SULI_LESSON_BALANCE_CALCULATION === 'true'
  ) {
    const { student, billingType, start_time, end_time } = fetchedEvent;

    const lessonFactorForBillingType =
      student?.organization?.lesson_duration_minutes /
      billingType?.duration_minutes;

    const billingTypeFactorForEvent =
      dayjs(end_time).diff(start_time, 'minute') /
      billingType?.duration_minutes;

    const lessonPrice =
      billingType?.price *
      billingTypeFactorForEvent *
      lessonFactorForBillingType;

    balanceInLessons = Math.round(student?.balance / lessonPrice);
  }

  return (
    <div className="divide-y">
      {fetchedEvent.student && (
        <EventDescriptionItem label={t('studentLabel')}>
          <div className="flex items-center">
            <div className="flex-grow">
              <Link href={Routes.Students.DetailView(fetchedEvent.student.id)}>
                <a className="hover:text-accent-600 inline-block mr-4 mb-1 mt-1">
                  <span className="align-middle">
                    {formatNameWithLabel(fetchedEvent.student)}
                  </span>
                  <ChevronRightIcon className="ml-1 w-4 h-4 stroke-2 inline-block align-middle" />
                </a>
              </Link>
            </div>
            {isFeatureEnabled(RestrictedFeatures.SYLLABI) && (
              <Link
                href={
                  syllabusProgressCard
                    ? Routes.Students.ProgressCards(
                        fetchedEvent.student.id,
                      ).DetailView(syllabusProgressCard.id, fetchedEvent.id)
                    : Routes.Students.ProgressCards(fetchedEvent.student.id)
                        .Overview
                }
                passHref
              >
                <a>
                  <Button icon={ClipboardDocumentCheckIcon} color="secondary">
                    {syllabusProgressCard ? t('addRating') : t('progressCards')}
                  </Button>
                </a>
              </Link>
            )}
          </div>
          {fetchedEvent.student.balance != null && (
            <p className="text-xs">
              {t('balanceLabel')}
              {': '}
              <span className={getTextColor(fetchedEvent.student.balance)}>
                {formatCurrency(fetchedEvent.student.balance)}
              </span>
              {balanceInLessons != null && (
                <>
                  {' '}
                  ({balanceInLessons}{' '}
                  {t('lessonsUnit', {
                    count: balanceInLessons,
                  })}
                  )
                </>
              )}
            </p>
          )}
        </EventDescriptionItem>
      )}
      {fetchedEvent.billingType && (
        <EventDescriptionItem label={t('billingTypeLabel')}>
          <EventDescriptionLink
            showLink={hasReadAccess(Permission.BILLING_TYPES)}
            href={Routes.BillingTypes.DetailView(fetchedEvent.billingType.id)}
          >
            {fetchedEvent.billingType.title}
          </EventDescriptionLink>
        </EventDescriptionItem>
      )}
      <div className="grid grid-cols-2 gap-4">
        {fetchedEvent.vehicle && (
          <EventDescriptionItem label={t('vehicleLabel')}>
            <EventDescriptionLink
              href={Routes.Vehicles.DetailView(fetchedEvent.vehicle.id)}
              showLink={hasReadAccess(Permission.VEHICLE_DETAIL)}
            >
              {fetchedEvent.vehicle.title || fetchedEvent.vehicle.license_plate}
            </EventDescriptionLink>
          </EventDescriptionItem>
        )}
        {fetchedEvent.secondaryVehicle && (
          <EventDescriptionItem label={t('secondaryVehicleLabel')}>
            <EventDescriptionLink
              showLink={hasReadAccess(Permission.VEHICLE_DETAIL)}
              href={Routes.Vehicles.DetailView(
                fetchedEvent.secondaryVehicle.id,
              )}
            >
              {fetchedEvent.secondaryVehicle.title ||
                fetchedEvent.secondaryVehicle.license_plate}
            </EventDescriptionLink>
          </EventDescriptionItem>
        )}
      </div>
      {fetchedEvent.meetingPoint && (
        <EventDescriptionItem label={t('meetingPointLabel')}>
          {fetchedEvent.meetingPoint.title}{' '}
          {!containsAllNull(fetchedEvent.meetingPoint.address) && (
            <Link href={gmapsNavigationLink(fetchedEvent.meetingPoint.address)}>
              <a target="_blank" className="hover:text-accent-600">
                ({formatAddress(fetchedEvent.meetingPoint.address)})
              </a>
            </Link>
          )}
        </EventDescriptionItem>
      )}
      {!containsAllNull(fetchedEvent.meetingAddress) && (
        <EventDescriptionItem label={t('meetingAddressLabel')}>
          <EventDescriptionLink
            showLink
            href={gmapsNavigationLink(fetchedEvent.meetingAddress)}
          >
            {formatAddress(fetchedEvent.meetingAddress)}
          </EventDescriptionLink>
        </EventDescriptionItem>
      )}
      {fetchedEvent.description && (
        <EventDescriptionItem label={t('descriptionLabel')}>
          <div className="text-l">{fetchedEvent.description}</div>
        </EventDescriptionItem>
      )}
      {fetchedEvent.user && (
        <EventDescriptionItem label={t('userLabel')}>
          <div>{formatName(fetchedEvent.user)}</div>
        </EventDescriptionItem>
      )}
    </div>
  );
};
